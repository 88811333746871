import './App.css';
import SignIn from './SignIn';
import RegisterTenant from './RegisterTenant';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (

    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<SignIn />} />
          <Route path="/RegisterTenant" element={<RegisterTenant />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
