import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logoarmada from "./assets/logos/armada.png";
import logoaisa from "./assets/logos/alphainnovations.svg";
import logocet from "./assets/logos/cetpower.svg";


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.alphainnovations.eu/">
        Alpha Innovations SA
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();


export default function RgisterTenant() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const req = {
      organization: data.get('Organization'),
      adminEmail: data.get('AdminEmail'),
      adminPassword: data.get('AdminPassword'),
      adminFirstName: data.get('AdminFirstName'),
      adminLastName: data.get('AdminLastName')
    }
    fetch("/api/register/tenant", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    }).then(async (response) => {
      if (response.status !== 200) {
        let data = await response.json();
        alert("Error: " + data);
      }
      else {
        let data = await response.json();
        alert("Your tenant has been created : " + data.tenant + " \r\nLogin is 'admin' and password is the one you entered");
        var a = window.location.protocol + "//" + window.location.hostname;
        if (window.location.port !== 443 && window.location.port !== 80) {
          a += (":" + window.location.port);
        }
        window.location.replace(a);
      }
    }
    )
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >



          <Typography component="h2" variant="h5">

            <img src={logoaisa} style={{ maxWidth: '8em' }} alt="" />
            <img src={logoarmada} style={{ maxWidth: '5em' }} alt="" />
            <img src={logocet} style={{ maxWidth: '8em' }} alt="" />
          </Typography>
          <Typography component="h2" variant="h5">
            ARMADA - INVIEW MESH
          </Typography>
          <Typography component="h2" variant="h5">
            Create Your Tenant:
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="Organization"
              label="Organization (Tenant Name)"
              name="Organization"
              autoComplete="Organization"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="AdminFirstName"
              label="Administrator First Name"
              name="AdminFirstName"
              autoComplete="FirstName"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="AdminLastName"
              label="Administrator Last Name"
              name="AdminLastName"
              autoComplete="LastName"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="AdminEmail"
              label="Administrator Email"
              autoComplete="email"
              name="AdminEmail"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="AdminPassword"
              label="Administrator Password"
              type="password"
              id="AdminPassword"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Create My Tenant
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
