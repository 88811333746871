import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { Link as RouterLink } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logoarmada from "./assets/logos/armada.png";
import logoaisa from "./assets/logos/alphainnovations.svg";
import logocet from "./assets/logos/cetpower.svg";


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.alphainnovations.eu/">
        Alpha Innovations SA
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();


export default function SignIn() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const req = {
      tenant: data.get('tenant'),
      login: data.get('login'),
      password: data.get('password')
    }
    const queryParams = new URLSearchParams(window.location.search);
    console.log(queryParams);
    const redirect = queryParams.get("redirect");
    console.log(redirect);
    fetch("/api/login", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    }).then(async (response) => {

      if (response.status < 200 || response.status >= 300) {
        let data = await response.json();
        alert(data.title !== null ? data.title : "Bad credentials, please try again");
      }
      else {
        localStorage.setItem('username', data.get('login'));
        var a = window.location.protocol + "//" + window.location.hostname;
        if (window.location.port !== 443 && window.location.port !== 80) {
          a += (":" + window.location.port);
        }
        if (redirect !== null) {
          window.location.replace(a + redirect);
        }
        else {
          window.location.replace(a + "/" + req.tenant + "/V2/");
        }
      }
    }
    )
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >



          <Typography component="h2" variant="h5">

            <img src={logoaisa} style={{ maxWidth: '8em' }} alt="" />
            <img src={logoarmada} style={{ maxWidth: '5em' }} alt="" />
            <img src={logocet} style={{ maxWidth: '8em' }} alt="" />
            ARMADA - INVIEW MESH
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="tenant"
              label="Tenant"
              name="tenant"
              autoComplete="tenant"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="login"
              label="Login"
              autoComplete="login"
              name="login"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link component={RouterLink} to="/RegisterTenant" variant="body2">
                  {"Don't have a tenant yet ? Create one!"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
